import { isAndroid, isMobile } from "common/utils/browser";

const $getAppPromoBanners = () => document.querySelectorAll(".js-header-app-promo-banner");
const $getAppStoreLink = () => document.querySelector(".js-app-store-btn");
const $getPlayStoreLink = () => document.querySelector(".js-play-store-btn");

const setBannerLink = ($banner, href) => {
  $banner.setAttribute("href", href);
  $banner.setAttribute("target", "_blank");
  $banner.setAttribute("rel", "noopener noreferrer");
};

const initAppPromoBanner = () => {
  const $banners = $getAppPromoBanners();
  $banners.forEach($banner => {
    if (window.mobileApp || !isMobile()) return;
    const $playStoreLink = $getPlayStoreLink();
    const $appStoreLink = $getAppStoreLink();

    if (isAndroid() && $playStoreLink) {
      setBannerLink($banner, $playStoreLink.href);
    } else if ($appStoreLink) {
      setBannerLink($banner, $appStoreLink.href);
    }
  });
};

export default initAppPromoBanner;
