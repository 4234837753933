/* eslint-disable max-lines-per-function, max-lines */
import { sendDataLayerOnLinkClickEvent, track } from "jiffy-analytics";
import { isVisible } from "common/utils/ui/element";
import initAppPromoBanner from "./app-promotion-banner";
import "./header-promo-carousel.scss";

const SWIPE_ANIMATION_TIMING = 150;
const CLICK_ANIMATION_TIMING = 300;
const AUTOPLAY_ANIMATION_TIMING = 7900;
// Need to add time gap avoid overlapping slides
const TIME_GAP_BETWEEN_SLIDES = 100;
let isAnimating = false;
let curSlideIdx = 0;
let stopAutoplay;

// Getters
const $getWrapper = () => document.querySelector(".js-header-promo-carousel");
const $getGrid = () => document.querySelector(".js-header-promo-carousel-banners-grid");
const $getSlides = (tagName = "") => document.querySelectorAll(`${tagName}.js-header-promo-banner`);
const $getArrows = () => {
  const $arrowLeft = document.querySelector(".js-header-promo-carousel-arrow-left");
  const $arrowRight = document.querySelector(".js-header-promo-carousel-arrow-right");

  return {
    $arrowLeft,
    $arrowRight
  };
};

const getNextSlideIdx = direction => {
  const slidesCount = $getSlides().length;
  if (direction === "prev") return curSlideIdx - 1 < 0 ? slidesCount - 1 : curSlideIdx - 1;
  if (direction === "next") return curSlideIdx + 1 >= slidesCount ? 0 : curSlideIdx + 1;

  return curSlideIdx;
};
const hasSliderFocus = () => {
  const { $arrowLeft, $arrowRight } = $getArrows();
  const $domList = [...$getSlides(), $arrowLeft, $arrowRight];
  const isFocusOnElement = $domList.some($item => $item === document.activeElement);
  return isFocusOnElement && isVisible($getWrapper);
};

// Setters
const setActiveSlide = activeIdx => {
  $getSlides().forEach(($slide, idx) => {
    const isActiveSlide = activeIdx === idx;
    $slide.classList.toggle("active", isActiveSlide);
    $slide.setAttribute("tabindex", isActiveSlide ? "0" : "-1");
    if (hasSliderFocus() && isActiveSlide) $slide.focus();
  });
};
const setSwipeAnimation = (slideIdx, action = "end", shift = 0) => {
  const $slide = $getSlides()[slideIdx];
  const clearSlideAttrs = () => {
    $slide.classList.remove("swipe-transition", "swiped");
    $slide.removeAttribute("style");
  };

  $slide.style.transform = `translate(${shift})`;

  if (action === "start") {
    $slide.classList.add("swiped");
  } else if (action === "end") {
    $slide.classList.add("swipe-transition");
    setTimeout(clearSlideAttrs, SWIPE_ANIMATION_TIMING);
  } else if (action === "clear") {
    clearSlideAttrs();
  }
};

// Actions
const scrollInfinityLeft = () => {
  if (isAnimating) return;

  const $grid = $getGrid();
  const slidesCount = $getSlides().length;

  isAnimating = true;
  curSlideIdx = getNextSlideIdx("prev");
  setActiveSlide(curSlideIdx);
  $grid.classList.toggle("scroll-left-first", curSlideIdx === slidesCount - 1);
  $grid.classList.toggle("scroll-left", curSlideIdx !== slidesCount - 1);

  setTimeout(() => {
    $grid.classList.remove("scroll-left", "scroll-left-first");

    setTimeout(() => {
      isAnimating = false;
    }, TIME_GAP_BETWEEN_SLIDES);
  }, CLICK_ANIMATION_TIMING);
};

const scrollInfinityRight = () => {
  if (isAnimating) return;

  const $grid = $getGrid();

  isAnimating = true;
  curSlideIdx = getNextSlideIdx("next");
  $grid.classList.toggle("scroll-right-last", curSlideIdx === 0);
  $grid.classList.toggle("scroll-right", curSlideIdx !== 0);

  setTimeout(() => {
    $grid.classList.remove("scroll-right", "scroll-right-last");
    setActiveSlide(curSlideIdx);

    setTimeout(() => {
      isAnimating = false;
    }, TIME_GAP_BETWEEN_SLIDES);
  }, CLICK_ANIMATION_TIMING);
};

const addAutoPlayInterval = () => {
  const $wrapper = $getWrapper();
  clearInterval(window.carouselAutoplayInterval);
  $wrapper.classList.add("autoplay");

  window.carouselAutoplayInterval = setInterval(() => {
    $wrapper.classList.remove("autoplay");
    setTimeout(() => {
      $wrapper.classList.add("autoplay");
    }, TIME_GAP_BETWEEN_SLIDES);
    scrollInfinityRight();
  }, AUTOPLAY_ANIMATION_TIMING + TIME_GAP_BETWEEN_SLIDES);

  return () => {
    $wrapper.classList.remove("autoplay");
    clearInterval(window.carouselAutoplayInterval);
  };
};

const createNewAutoplay = () => {
  if (stopAutoplay) {
    stopAutoplay();
    setTimeout(() => {
      stopAutoplay = addAutoPlayInterval();
    }, TIME_GAP_BETWEEN_SLIDES);
  }
};

// Init functions
const initAutoplayCarousel = () => {
  const $wrapper = $getWrapper();

  stopAutoplay = addAutoPlayInterval();
  $wrapper.addEventListener("mouseenter", () => {
    stopAutoplay();
  });
  $wrapper.addEventListener("mouseleave", () => {
    stopAutoplay = addAutoPlayInterval();
  });

  document.addEventListener("focusin", () => {
    if (hasSliderFocus()) {
      stopAutoplay();
    } else {
      stopAutoplay = addAutoPlayInterval();
    }
  });
};

const initSwipeCarousel = () => {
  let touchstartX = 0;
  let prevStylesIdx = 0;
  let styledSlideIdx = 0;

  const $grid = $getGrid();
  const swipeDirection = touchendX => (touchendX < touchstartX ? "prev" : "next");

  $grid.addEventListener("touchstart", e => {
    touchstartX = e.changedTouches[0].screenX;
  });

  $grid.addEventListener("touchend", e => {
    const touchendX = e.changedTouches[0].screenX;
    const startSwipe = Math.min(window.innerWidth / 5, 100);

    // Need to scroll more or equal 20% of screen for start of swiping
    if (Math.abs(touchstartX - touchendX) < startSwipe) {
      setSwipeAnimation(styledSlideIdx, "clear");
      setSwipeAnimation(curSlideIdx, "clear");
      return;
    }

    if (swipeDirection(touchendX) === "prev") {
      setSwipeAnimation(styledSlideIdx, "end", "0px");
      setSwipeAnimation(curSlideIdx, "end", "-100%");
    }
    if (swipeDirection(touchendX) === "next") {
      setSwipeAnimation(styledSlideIdx, "end", "0px");
      setSwipeAnimation(curSlideIdx, "end", "100%");
    }

    curSlideIdx = styledSlideIdx;
    setActiveSlide(curSlideIdx);
    createNewAutoplay();
  });

  $grid.addEventListener("touchmove", e => {
    const touchendX = e.changedTouches[0].screenX;
    styledSlideIdx = getNextSlideIdx(swipeDirection(touchendX));

    if (styledSlideIdx !== prevStylesIdx) {
      setSwipeAnimation(prevStylesIdx, "clear");
    }
    if (swipeDirection(touchendX) === "prev") {
      setSwipeAnimation(styledSlideIdx, "start", `calc(100% - ${touchstartX - touchendX}px)`);
      setSwipeAnimation(curSlideIdx, "start", `calc(0% - ${touchstartX - touchendX}px)`);
    }
    if (swipeDirection(touchendX) === "next") {
      setSwipeAnimation(styledSlideIdx, "start", `calc(-100% - ${touchstartX - touchendX}px)`);
      setSwipeAnimation(curSlideIdx, "start", `calc(-0% - ${touchstartX - touchendX}px)`);
    }

    prevStylesIdx = styledSlideIdx;
  });
};

const handlesCarousel = () => {
  const { $arrowLeft, $arrowRight } = $getArrows();

  $arrowLeft.addEventListener("click", e => {
    e.stopPropagation();
    scrollInfinityLeft();
    createNewAutoplay();
  });
  $arrowRight.addEventListener("click", e => {
    e.stopPropagation();
    scrollInfinityRight();
    createNewAutoplay();
  });
};

const initCarousel = () => {
  const $wrapper = $getWrapper();
  if (!$wrapper) return;

  if ($wrapper.classList.contains("autoplay")) initAutoplayCarousel();
  initSwipeCarousel();
  handlesCarousel();
};

const initGAEvents = () => {
  $getSlides("a").forEach($banner => {
    const { promoName } = $banner.dataset;

    $banner.addEventListener("click", e => {
      sendDataLayerOnLinkClickEvent(e, {
        genericEvent: true,
        eventCategory: "Main Banner",
        event: "click",
        eventLabel: promoName
      });

      track("header_banner_clicked", $banner.dataset);
    });
  });
};

export default () => {
  initCarousel();
  initGAEvents();
  initAppPromoBanner();
};
